#gameEnvironment {
  display: none;
}

#tutorialScreen {
  background-color: black;
  text-align: center;
  display: in-line;
  border: 5px ridge red;
}
#canvas {
  border: 1px solid black;
  padding: 0px;
  margin-top: 0px;
}
body {
  margin: 0px;
}
#player1 {
  padding: 0px;
  margin-top: 320px;
  margin-left: 10px;
  background-color: "blue";
  font: bold 30px Impact;
}
#player2 {
  padding: 0px;
  margin-top: 320px;
  margin-left: 1193px;
  background-color: "blue";
  font: bold 30px Impact;
}
#gravityToggle {
  background-color: black;
  color: white;
  font: 20px Impact;
}
.tutorialText {
  color: white;
  font: 60px Impact;
  padding: 0px;
}
.key {
  padding: 5px 10px 5px 10px;
  background-color: gray;
}

.clickToPlay {
  background-color: #55ce33;
  margin: 20px 0px 10px 0px;
  font: 20px Arial;
  color: white;
}
